<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12 lg11 pa-0 pa-sm-4>
        <v-card
          class="mx-auto elevation-2"
          align="center"
          outlined
        >
          <v-layout wrap justify-center class="pa-2 gradientCard" v-if="stData">
            <v-flex xs1stData2 text-right align-self-end>
              <!-- <deleteStudent style="cursor: pointer" /> -->
            </v-flex>
            <v-flex xs12 align-self-center v-if="stData">
              <v-layout wrap justify-center v-if="stData">
               <v-layout wrap>
               <v-flex xs1></v-flex>
                <v-flex xs10 text-center>
                  <v-avatar size="70px" radius="10px">
                    <img
                      src="@/assets/images/Ellipse23.png"
                      alt="John"
                      class="pa-1"
                    />
                  </v-avatar>
                </v-flex>
                 <v-flex xs1 text-right>
                  <v-icon
                    @click="$router.go(-1)"
                    title="Back"
                    style="cursor: pointer; color: #766bc0"
                    >mdi-arrow-left</v-icon
                  >
                </v-flex>
               </v-layout>
                <v-flex xs12 pb-2 text-center>
                  <span
                    class="nssbold"
                    v-if="stData"
                    style="font-size: 18px; color: #ffffff"
                  >
                    {{ stData.name }}
                  </span>
                </v-flex>
                <v-flex xs12 text-center>
                  <span
                    class="nsregular"
                    v-if="stData"
                    style="font-size: 12px; color: #ffffff"
                  >
                    Father's Name : {{ stData.nameofFather }}
                  </span>
                </v-flex>
                <v-flex xs12 text-center>
                  <span
                    class="nsregular"
                    v-if="stData"
                    style="font-size: 12px; color: #ffffff"
                  >
                    Mother's Name : {{ stData.nameOfMother }}
                  </span>
                </v-flex>
                <v-flex xs12 pb-2 text-center>
                  <span
                    class="nsregular"
                    v-if="stData"
                    style="font-size: 12px; color: #ffffff"
                  >
                    Contact : {{ stData.parentContactNumber }}
                  </span>
                </v-flex>
                <v-flex xs12 text-center>
                  <v-chip
                    elevation="2"
                    rounded
                    color="#766BC0"
                    class="white--text"
                    style="font-weight: 500; font-size: 14px"
                    v-if="stData"
                  >
                    <span v-if="stData.Class">
                      {{ stData.Class }}-
                    </span><span v-if="stData.division">
                      {{stData.division}}
                    </span>
                  </v-chip>
                  <v-chip
                    dark
                    elevation="2"
                    class="ml-2"
                    rounded
                    outlined
                    style="font-weight: 400; font-size: 14px"
                    >Adm. No.: {{ stData.admissionNo }}</v-chip
                  >
                  <v-chip
                    dark
                    elevation="2"
                    class="ml-2"
                    rounded
                    outlined
                    style="font-weight: 400; font-size: 14px"
                    v-if="stData.school"
                    >School: {{ stData.school.SchoolName }}</v-chip
                  >
                  <v-chip
                  v-if="stData.roomNumber"
                    dark
                    elevation="2"
                    class="ml-2"
                    rounded
                    outlined
                    style="font-weight: 400; font-size: 14px"
                    >Room. No.: {{ stData.roomNumber.roomNo }}</v-chip
                  >
                  <v-chip
                    dark
                    elevation="2"
                    class="ml-2"
                    rounded
                    outlined
                    style="font-weight: 400; font-size: 14px"
                    v-if="stData.mainHostelId"
                    >Hostel: {{ stData.mainHostelId.hostelName}} ({{ stData.hostelBlock.Block }})</v-chip
                  >

                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
        <v-card
          class="mx-auto elevation-2 px-6"
          align="center"
          outlined
        >
          <!----------------------filters---------------------------->
          <!-- <v-layout wrap>
          <v-flex xs12 md6 lg3 pt-md-6>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="fromDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  label="From"
                  outlined
                  readonly
                  dense
                  clearable
                  class="rounded-0"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                no-title
                scrollable
                @change="$refs.menu.save(fromDate)"
              >
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 md6 lg3 pl-md-4 pt-md-6>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="toDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  label="To"
                  readonly
                  outlined
                  dense
                  clearable
                  class="rounded-0"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                no-title
                scrollable
                @change="$refs.menu2.save(toDate)"
              >
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 md6 lg3 pl-lg-4 pt-md-6>
            <v-text-field
              v-model="keyword"
              dense
              label="Search : Ad.no/Name"
              outlined
              clearable
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg3 pl-md-4 pt-md-6>
             <v-select
                    :items="issueStatus "
                    v-model="cvalue"
                    label="Status"
                    outlined
                    hide-details
                    dense
                  ></v-select>
          </v-flex>
        </v-layout> -->
          <!--------------------------list------------------------>
          <v-tabs color="#766BC0" grow class="nsbold" style="font-size: 16px">
            <v-tab>Leave({{leaveCount}})</v-tab>
            <v-tab>Outpass({{outpassCount}})</v-tab>
            <v-tab>Visitors History({{visitorsCount}})</v-tab>
            <v-tab>Incident Reports({{apologyCount}})</v-tab>
            <v-tab>Permissions History ({{ permissionCount}})</v-tab>
            <!-------------------------LEAVE TAB--------------------->
            <v-tab-item>
              <v-layout wrap justify-center>
            <v-flex xs12 v-if="leave" pt-2>
              <v-layout wrap py-md-2 class="nsbold" style="font-size: 16px">
                <v-flex xs12 pa-md-2 v-if="leave.length > 0">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left" style="font-size:15px;">No.</th>
                          <!-- <th class="text-left">Admission No</th>
                          <th class="text-left">Name</th>
                          <th style="width:6%" class="text-left">Class</th> -->
                          <th style="width:17%; font-size:15px;" class="text-left">From Date</th>
                          <th style="width:17%; font-size:15px;" class="text-left">To Date</th>
                          <th class="text-left; font-size:15px;" style="font-size:15px;">Purpose</th>
                          <th class="text-left; font-size:15px;" style="font-size:15px;">Status</th>
                          <th style="width:20%; font-size:15px;" class="text-left">Extention Status<span >&nbsp;</span></th>
                          <!-- <th class="text-left">Approve</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in leave" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                          <!-- <td>
                            {{ item.admissionNo }}
                          </td>
                          <td>{{ item.studentid.name }}</td>
                          <td>
                            <span v-if="item.studentid">
                              {{ item.studentid.Class }} :
                              {{ item.studentid.division }}
                            </span>
                            <span v-else>NA</span>
                          </td> -->
                          <td>
                            <!-- {{item.fromDate}} -->
                            <span v-if="item.fromDate">
                              {{ formatDate(item.fromDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.toDate">
                              {{ formatDate(item.toDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.purpose">
                              {{ item.purpose }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <!-- <span v-if="item.leaveStatus == 'wApproved'">
                              Warden Approved
                            </span>
                            <span v-else-if="item.leaveStatus == 'Rejected'">
                              Rejected
                            </span>
                            <span v-else-if="item.leaveStatus == 'vcApproved'">
                              Vice Principal Approved
                            </span>
                            <span v-else-if="item.leaveStatus == 'Pending'">
                              Pending
                            </span> -->

                            <span v-if="item.leaveStatus">
                              {{ item.leaveStatus }}
                            </span>
                            <span v-else>-</span>
                            <!-- <span v-if="item.leaveExtended==true">
                    <v-img  height="20px" width="15px" contain src="./../../assets/images/acc.webp"></v-img>

                            </span> -->
                          </td>
                          <td  >
                            <v-layout wrap   class="text-right"  v-if="item.leaveExtended == true" >
                              <v-flex  pa-1 text-left>
                                <span>Extention Present</span>
                              </v-flex>
                              <v-flex text-left  pa-1 >
                                <span>
                                  <v-img 
                                  title="View"
                                    height="30px"
                                    width="25px"
                                    contain
                                    @click="extenddialoge=true , Extent = item.extensions    "
                                    src="./../../assets/images/icon2.png"
                                  ></v-img>
                                </span>
                              </v-flex>
                            </v-layout>
                            
                            <span v-else> No Extentions </span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
                <v-flex v-else xs12 align-self-center text-center pa-2>
                  <span class="nsregular">Currently no item found!</span>
                </v-flex>
              </v-layout>
              <!-------------------dialogue box--------------------->
              <v-dialog width="1000px" v-model="extenddialoge">
                <v-card width="1000px">
                  <v-toolbar color="#766BC0" dense flat class="body-2">
                    <v-toolbar-title style="color: white"
                      >Extention List</v-toolbar-title
                    >
                  </v-toolbar>
                  <v-layout>
                    <v-flex>
                      <v-simple-table class="px-2">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th class="text-left">From Date</th>
                          <th class="text-left">To Date</th>
                          <th class="text-left">Extended Date</th>
                          <th class="text-left">Extention Purpose</th>
                          <th class="text-left">No.of Days</th>
                          <th class="text-left">Status</th>
                          <th class="text-left">Comments</th>
                       
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item2, i) in Extent" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                         
                          <td>
                            <span v-if="item2.oldFromDate">
                              {{ formatDate(item2.oldFromDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item2.oldToDate">
                              {{ formatDate(item2.oldToDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                           <td>
                            <span v-if="item2.toDate">
                              {{ formatDate(item2.toDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item2.purpose">
                              {{ item2.purpose }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="item2.numberOfDays">
                              {{ item2.numberOfDays }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <!-- <span v-if="item2.leaveStatus=='wApproved'">
                              Warden Approved
                            </span>
                            <span v-else-if="item2.leaveStatus=='vcApproved'">
                              VicePrincipal Approved
                            </span>
                            <span v-else-if="item2.leaveStatus=='Rejected'">
                           Rejected by :  {{item2.rejectedBy.name}}
                            </span>
                            <span v-else-if="item2.leaveStatus=='Pending'">
                           Pending
                            </span> -->
                            <span v-if="item2.leaveStatus">
                              {{ item2.leaveStatus }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="item2.leaveStatus=='Rejected'">
                            {{item2.rejectReason}}
                            </span>
                           
                            <span v-else>-</span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  </v-flex>
                  </v-layout>
                  <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="grey"
                      text
                      class="body-2 font-weight-bold"
                      @click="extenddialoge = false"
                      >Close</v-btn
                    >
                    <!-- <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="approveData()"
                  >Approve</v-btn
                > -->
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!------------------------------------------>
              <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
            </v-tab-item>
            <!-------------------------OUTPASS TAB--------------------->
            <v-tab-item>
              <v-layout wrap justify-center>
                <v-flex xs12 v-if="outpass" px-0 px-md-6>
                  <v-layout wrap pb-md-2 px-md-2 class="nsbold" style="font-size: 16px">
                    <v-flex xs12 pa-md-2 v-if="outpass.length > 0">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left" style="font-size:15px;">No.</th>
                              <th class="text-left" style="font-size:15px;">Outpass.No.</th>
                              <th class="text-left" style="font-size:15px;">Date</th>
                              <th class="text-left" style="font-size:15px;">From</th>
                              <th class="text-left" style="font-size:15px;">To</th>
                              <th class="text-left" style="font-size:15px;">Issued Date</th>
                              <th class="text-left" style="font-size:15px;">Status</th>
                              <th class="text-left" style="font-size:15px;">View</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, i) in outpass" :key="i">
                              <td>
                                <span v-if="page == 1">
                                  {{ i + 1 }}
                                </span>
                                <span v-else>
                                  {{ i + 1 + 20 * (page - 1) }}
                                </span>
                              </td>

                              <td>
                                <span v-if="item.outPassNo">
                                  {{ item.outPassNo }}
                                </span>
                                <span v-else>NA</span>
                              </td>
                             
                              <td>
                                <span v-if="item.fromDate">
                                  {{ formatDate2(item.fromDate) }}
                                </span>
                                <span v-else>NA</span>
                              </td>
                              <td>
                                <span v-if="item.fromDate">
                                  {{ formatDate3(item.fromDate) }}
                                </span>
                                <span v-else>NA</span>
                              </td>
                              <td>
                                <span v-if="item.toDate">
                                  {{ formatDate3(item.toDate) }}
                                </span>
                                <span v-else>NA</span>
                              </td>
                             
                              <td>
                                <span v-if="item.create_date">
                                  {{ formatDate(item.create_date) }}
                                </span>
                                <span v-else>NA</span>
                              </td>
                               <td>
                                <span v-if="item.issueStatus== 'Active'">
                                  Issued
                                </span>
                                <span v-else-if="item.issueStatus== ''">
                                  -
                                </span>
                                <span v-else>{{ item.issueStatus }}</span>
                                  
                              </td>
                               <td>
                                <!---------------outpass dialogue------------------------>
                                <v-dialog
                                  v-model="item.dialog"
                                 
                                  max-width="590"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      style="cursor: pointer; color: #766bc0"
                                      >mdi-eye</v-icon
                                    >
                                  </template>
                                  <v-card>
                                    <v-layout wrap>
                                      <v-flex xs12 pa-4>
                                        <v-layout wrap>
                                          <v-flex xs12 sm6 text-sm-left>
                                            <span
                                              class="nsbold"
                                              style="font-size: 16px"
                                            >
                                              Admission No:
                                              {{ item.admissionNo }}
                                            </span>
                                          </v-flex>
                                          <v-flex xs12 sm6 text-sm-right>
                                            <span
                                              class="nsbold"
                                              style="font-size: 16px"
                                            >
                                              Outpass No: {{ item.outPassNo }}
                                            </span>
                                          </v-flex>
                                        </v-layout>
                                        <v-divider></v-divider>
                                        <v-layout wrap pa-md-4>
                                          <v-flex xs12 md6 text-md-left>
                                            <span
                                              class="nsregular"
                                              style="font-size: 14px"
                                            >
                                              <b>Name:</b>
                                              {{ item.studentid.name }}
                                            </span>
                                          </v-flex>
                                          <v-flex xs12 md6 text-md-right>
                                            <span
                                              class="nsregular"
                                              style="font-size: 14px"
                                            >
                                              <b> Issue Date:</b>
                                              {{ item.create_date }}
                                            </span>
                                          </v-flex>
                                          <v-flex xs12 md6 text-md-left>
                                            <span
                                              class="nsregular"
                                              style="font-size: 14px"
                                            >
                                              <b>From:</b>
                                              {{ formatDate(item.fromDate) }}
                                            </span>
                                          </v-flex>
                                          <v-flex xs12 md6 text-md-right>
                                            <span
                                              class="nsregular"
                                              style="font-size: 14px"
                                            >
                                              <b>Isssued By:</b>
                                              {{ item.issuingAuthority }}
                                            </span>
                                          </v-flex>
                                          <v-flex xs12 md6 text-md-left>
                                            <span
                                              class="nsregular"
                                              style="font-size: 14px"
                                            >
                                              <b>To:</b>
                                              {{ formatDate(item.toDate) }}
                                            </span>
                                          </v-flex>
                                          <v-flex xs12 md6 text-md-right>
                                            <span
                                              class="nsregular"
                                              style="font-size: 14px"
                                            >
                                              <b> Status:</b>
                                              {{ item.issueStatus }}
                                            </span>
                                          </v-flex>
                                          <v-flex xs12 md6 text-md-left>
                                            <span
                                              class="nsregular"
                                              style="font-size: 14px"
                                            >
                                              <b>Purpose:</b> {{ item.purpose }}
                                            </span>
                                          </v-flex>
                                          <v-flex xs12 md6 text-md-right>
                                            <span
                                              class="nsregular"
                                              style="font-size: 14px"
                                            >
                                              <b>Destination:</b>
                                              {{ item.destination }}
                                            </span>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>

                                    <v-card-actions>
                                      <v-spacer></v-spacer>
<!-- 
                                      <v-btn
                                        color="#766BC0"
                                        text
                                        @click="item.dialog =false"
                                      >
                                        Close
                                      </v-btn> -->
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                                <!--------------------------------------->

                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-flex>
                    <v-flex v-else xs12 align-self-center text-center pa-2>
                      <span class="nsregular">Currently no record found!</span>
                    </v-flex>
                  </v-layout>
                  <v-pagination
                    small
                    color="#766BC0"
                    total-visible="7"
                    v-model="page"
                    :length="Pagelength"
                  >
                  </v-pagination>
                </v-flex>
              </v-layout>
            </v-tab-item>
             <!-----------------------Visitor TAB--------------------->
            <v-tab-item>
              <v-layout wrap justify-center>
                <v-flex xs12 v-if="visitors" px-0 px-md-6>
                  <v-layout wrap pb-md-2 px-md-2 class="nsbold" style="font-size: 16px">
                    <v-flex xs12 pa-md-2 v-if="visitors.length > 0">
                     <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left nsbold"><span style="font-size:13px" class="nsbold">No.</span></th>
                          <th style="width:17%" class="text-left nsbold"><span style="font-size:13px" class="nsbold">From(Time)</span></th>
                          <th style="width:17%" class="text-left nsbold"><span style="font-size:13px" class="nsbold">To (Time)</span></th>
                          <th class="text-left nsbold"><span style="font-size:13px" class="nsbold">Purpose</span></th>
                          <th class="text-left nsbold"><span style="font-size:13px" class="nsbold">Status</span></th>
                          <th  class="text-left nsbold"><span style="font-size:13px" class="nsbold">View</span></th>
                          <!-- <th class="text-left">Approve</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in visitors" :key="i">
                          <td>
                            <span v-if="page == 1" class="nsregular">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                         
                          <td>
                            <span v-if="item.timeFrom" class="nsregular">
                              {{ formatDate(item.timeFrom) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.timeTo" class="nsregular">
                              {{ formatDate(item.timeTo) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.purpose" class="nsregular">
                              {{ item.purpose }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="item.visitStatus" class="nsregular">
                              {{item.visitStatus}}
                            </span>
                           
                            <span v-else>-</span>
                          </td>
                          <td  >
                            <v-layout wrap   class="text-right"  v-if="item.visitor" >
                             <v-icon @click="visitordialoge=true , VExtent = item.visitor " color="#766BC0">mdi-eye</v-icon>
                            </v-layout>
                            
                            <span v-else> - </span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                    </v-flex>
                    <v-flex v-else xs12 align-self-center text-center pa-2>
                      <span class="nsregular">Currently no record found!</span>
                    </v-flex>
                  </v-layout>
                  <!-------------------dialogue box--------------------->
             
              <v-dialog width="500px" v-model="visitordialoge">
                <v-card width="500px">
                  <v-toolbar color="#766BC0" dense flat class="body-2">
                    <v-toolbar-title style="color: white"
                      >Visitors List</v-toolbar-title
                    >
                  </v-toolbar>
                  <v-layout>
                    <v-flex>
                      <v-simple-table class="px-2">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">No.</th>
                          <!-- <th class="text-left">From Date</th>
                          <th class="text-left">To Date</th> -->
                          <!-- <th class="text-left">Extended Date</th> -->
                          <th class="text-center">name</th>
                          <th class="text-center">relationship</th>
                          <!-- <th class="text-left">Status</th>
                          <th class="text-left">Reason</th> -->
                       
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in VExtent" :key="i" class="text-center">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                        
                          <!-- <td>
                            <span v-if="item.oldFromDate">
                              {{ formatDate(item.oldFromDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.oldToDate">
                              {{ formatDate(item.oldToDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                           <td>
                            <span v-if="item.toDate">
                              {{ formatDate(item.toDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td> -->
                          <td>
                            <span v-if="item.name">
                              {{ item.name }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="item.relationship">
                              {{ item.relationship }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <!-- <td>
                            <span v-if="item.leaveStatus=='wApproved'">
                              Warden Approved
                            </span>
                            <span v-else-if="item.leaveStatus=='vcApproved'">
                              VicePrincipal Approved
                            </span>
                            <span v-else-if="item.leaveStatus=='Rejected'">
                           Rejected by :  {{item.rejectedBy.name}}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="item.leaveStatus=='Rejected'">
                            {{item.rejectReason}}
                            </span>
                            <span v-else>-</span>
                          </td> -->
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  </v-flex>
                  </v-layout>
                  <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="grey"
                      text
                      class="body-2 font-weight-bold"
                      @click="visitordialoge = false"
                      >Close</v-btn
                    >
                    <!-- <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="approveData()"
                  >Approve</v-btn
                > -->
                  </v-card-actions>
                </v-card>
              </v-dialog>
                  <v-pagination
                    small
                    color="#766BC0"
                    total-visible="7"
                    v-model="page"
                    :length="Pagelength"
                  >
                  </v-pagination>
                </v-flex>
              </v-layout>
            </v-tab-item>
             <!-----------------------incident report TAB--------------------->
            <v-tab-item>
              <v-layout wrap justify-center>
                <v-flex xs12 v-if="apology" px-0 px-md-6>
                  <v-layout wrap pb-md-2 px-md-2 class="nsbold" style="font-size: 16px">
                    <v-flex xs12 pa-md-2 v-if="apology.length > 0">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left" style="font-size:15px;">No.</th>
                              <th class="text-left" style="font-size:15px;">Date</th>
                              <th class="text-left" style="font-size:15px;">Incident Reported</th>
                              <th class="text-left" style="font-size:15px;">View</th>
                              <th class="text-left">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item3, i) in apology" :key="i">
                              <td>
                                <span v-if="page == 1">
                                  {{ i + 1 }}
                                </span>
                                <span v-else>
                                  {{ i + 1 + 20 * (page - 1) }}
                                </span>
                              </td>
                              <td>
                                <span v-if="item3.day">
                                  {{ formatDate(item3.day) }}
                                </span>
                                <span v-else>NA</span>
                              </td>
                              <td>
                                <span v-if="item3.reason">
                                  {{item3.reason}}
                                </span>
                                <span v-else>NA</span>
                              </td>
                             
                         <td>
                             <v-btn
                          v-if="item3.isApology == false"
                          small
                          outlined
                          disabled
                          color="#766BC0"
                          
                        >
                          <!-- <v-icon small>mdi-plus</v-icon> Add Apology -->No
                          apology found
                        </v-btn>
                            <v-btn
                              v-if="item3.isApology == true"
                              small
                              outlined
                              color="#766BC0"
                              @click="
                                (viewdialogue = true),
                                  (apology2 = item3.photo)                              "
                            >
                              <v-icon small>mdi-plus</v-icon> View Apology
                            </v-btn>
                               
                            <v-form ref="form3">
                              <v-dialog width="400px" v-model="viewdialogue">
                                <v-card width="400px">
                                  <v-toolbar
                                    dark
                                    color="#766BC0"
                                    dense
                                    flat
                                    class="body-2"
                                  >
                                    <v-toolbar-title
                                      >Incident Description</v-toolbar-title
                                    >
                                  </v-toolbar>
                                  <v-layout wrap pa-4>
                                    <v-flex xs12 pa-1>
                                      <v-img
                                        height="400px"
                                        contain
                                        :src="mediaURL + apology2"
                                      >
                                      </v-img>
                                    </v-flex>
                                  </v-layout>

                                  <v-card-actions class="pt-3">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="grey"
                                      text
                                      class="body-2 font-weight-bold"
                                      @click="viewdialogue = false"
                                      >Close</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-form>
                        
                          </td>
                           <td>
                        <v-icon
                          small
                          style="cursor: pointer"
                          @click="(deletedialoge = true), (curId = item3._id)"
                          >mdi-delete-outline</v-icon
                        >
                      </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-flex>
                    <v-flex v-else xs12 align-self-center text-center pa-2>
                      <span class="nsregular">Currently no record found!</span>
                    </v-flex>
                  </v-layout>
                  <v-pagination
                    small
                    color="#766BC0"
                    total-visible="7"
                    v-model="page"
                    :length="Pagelength"
                  >
                  </v-pagination>
                </v-flex>
              </v-layout>
              <!-- delete -->
      <v-dialog width="400px" v-model="deletedialoge">
        <v-card width="400px">
          <v-toolbar dark color="#766BC0" dense flat class="body-2">
            <v-toolbar-title> Confirm </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4"
            >Are you sure you want to delete this record?
          </v-card-text>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="deletedialoge = false"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              class="body-2 font-weight-bold"
              outlined
              @click="deleteCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
            </v-tab-item>
            <!-----------------------Permission TAB--------------------->
             <v-tab-item>
              <v-layout wrap justify-center>
                <v-flex xs12 v-if="permission" px-0 px-md-6>
                  <v-layout wrap pb-md-2 px-md-2 class="nsbold" style="font-size: 16px">
                    <v-flex xs12 pa-md-2 v-if="permission.length > 0">
                      <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th style="width:17%" class="text-left">From(Time)</th>
                          <th style="width:17%" class="text-left">To (Time)</th>
                          <th class="text-left">Purpose</th>
                          <th class="text-left">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in permission" :key="i" py-4>
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                          <td>
                            <span v-if="item.fromTime">
                              {{ formatDate(item.fromTime) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.toTime">
                              {{ formatDate(item.toTime) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.reason">
                              {{ item.reason }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="item.permissionStatus">
                              {{item.permissionStatus}}
                            </span>
                           
                            <span v-else>-</span>
                            <!-- <span v-if="item.leaveExtended==true">
                    <v-img  height="20px" width="15px" contain src="./../../assets/images/acc.webp"></v-img>

                            </span> -->
 
                             <v-icon small v-if="item.permissionStatus=='Rejected'" @click="(permissiondialoge=true) , (permission2 = item) " color="#766BC0">mdi-eye</v-icon>
                           
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                    </v-flex>
                    <v-flex v-else xs12 align-self-center text-center pa-2>
                      <span class="nsregular">Currently no record found!</span>
                    </v-flex>
                    <v-dialog width="500px" v-model="permissiondialoge">
                <v-card width="500px">
                  <v-toolbar color="#766BC0" dense flat class="body-2">
                    <v-toolbar-title style="color: white"
                      >Rejection Info</v-toolbar-title
                    >
                  </v-toolbar>
                  <v-layout wrap pa-2>
                    <v-flex xs12 >
                   <span class="nsbold">Status:</span><span class="nsregular">{{permission2.permissionStatus}}</span> 
                  </v-flex>
                  <v-flex xs12 v-if="permission2.rejectedBy">
                     <span  class="nsbold">Rejected By: </span><span class="nsregular">{{permission2.rejectedBy.name}}</span>
                  </v-flex>
                  <v-flex xs12>
                     <span class="nsbold">Rejected Reason:</span><span class="nsregular"> {{permission2.rejectReason}}</span>
                  </v-flex>
                  </v-layout>
                  <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="grey"
                      text
                      class="body-2 font-weight-bold"
                      @click="permissiondialoge = false"
                      >Close</v-btn
                    >
                    <!-- <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="approveData()"
                  >Approve</v-btn
                > -->
                  </v-card-actions>
                </v-card>
              </v-dialog>
                  </v-layout>
                  <v-pagination
                    small
                    color="#766BC0"
                    total-visible="7"
                    v-model="page"
                    :length="Pagelength"
                  >
                  </v-pagination>
                </v-flex>
              </v-layout>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      apology2: null,
      VExtent:null,
      ServerError: false,
      extenddialoge: false,
      visitordialoge: false,
      viewdialogue: false,
      deletedialoge: false,
      permissiondialoge: false,
      permission2: [],
      Extent:null,
      curId: null,
      page: 1,
      Pagelength: 0,
      outpassCount: null,
      apologyCount: null,
      leaveCount: null,
      visitorsCount: null,
      permissionCount: null,
      
      outpass: [],
      leave: [],
      stData: [],
      apology: [],
      visitors: [],
      permission: [],
    };
  },
  mounted() {
    this.getData();
    this.getOutpass();
    this.getApology();
    this.getLeave();
    this.getVisitor();
    this.getPermission();
  },
  methods: {
    getData() {
      this.appLoading = true;

      //   let a;
      //   if (this.cvalue == "All") {
      //     a = "";
      //   } else {
      //     a = this.cvalue;
      //   }
      axios({
        method: "post",
        url: "/per/student/outpass",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          studentid: this.$route.query.id,
          page: this.page,
          limit: 20,
          //   keyword: this.keyword,
          //    from: this.fromDate,
          //   to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.outpass = response.data.data;
            this.stData = response.data.studentdetails;
            this.Pagelength = Math.ceil(response.data.totallength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    getOutpass() {
      this.appLoading = true;

      //   let a;
      //   if (this.cvalue == "All") {
      //     a = "";
      //   } else {
      //     a = this.cvalue;
      //   }
      axios({
        method: "get",
        url: "/outpasshistory/per/student",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          studentid: this.$route.query.id,
          page: this.page,
          limit: 20,
          //   keyword: this.keyword,
          //    from: this.fromDate,
          //   to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.outpass = response.data.data;
            this.outpassCount = response.data.totalLength;
            for (let i = 0; i < this.outpass.length; i++) {
              if (this.outpass[i].issueStatus == "Active") {
                this.outpass[i].issueStatus = "Issued";
              }
            }
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    getLeave() {
      this.appLoading = true;

      //   let a;
      //   if (this.cvalue == "All") {
      //     a = "";
      //   } else {
      //     a = this.cvalue;
      //   }
      axios({
        method: "get",
        url: "/per/student/leavehistory",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          studentid: this.$route.query.id,
          page: this.page,
          limit: 20,
          //   keyword: this.keyword,
          //    from: this.fromDate,
          //   to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.leave = response.data.data;
            this.leaveCount = response.data.totalLength;
            // for (let i = 0; i < this.outpass.length; i++) {
            //   if (this.outpass[i].issueStatus == "Active") {
            //     this.outpass[i].issueStatus = "Issued";
            //   }
            // }
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    getVisitor() {
      this.appLoading = true;

      //   let a;
      //   if (this.cvalue == "All") {
      //     a = "";
      //   } else {
      //     a = this.cvalue;
      //   }
      axios({
        method: "post",
        url: "/per/student/visitreport",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          studentid: this.$route.query.id,
          page: this.page,
          limit: 20,
          //   keyword: this.keyword,
          //    from: this.fromDate,
          //   to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.visitors = response.data.data;
            this.visitorsCount = response.data.totallength;
            this.Pagelength = Math.ceil(response.data.totallength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    getPermission() {
      this.appLoading = true;

      //   let a;
      //   if (this.cvalue == "All") {
      //     a = "";
      //   } else {
      //     a = this.cvalue;
      //   }
      axios({
        method: "post",
        url: "/per/student/studypermission",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          studentid: this.$route.query.id,
          page: this.page,
          limit: 20,
          //   keyword: this.keyword,
          //    from: this.fromDate,
          //   to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.permission = response.data.data;
            this.permissionCount = response.data.totallength;
            this.Pagelength = Math.ceil(response.data.totallength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
     getApology() {
      this.appLoading = true;

      //   let a;
      //   if (this.cvalue == "All") {
      //     a = "";
      //   } else {
      //     a = this.cvalue;
      //   }
      axios({
        method: "post",
        url: "/per/student/apology",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          studentid: this.$route.query.id,
          page: this.page,
          limit: 20,
          //   keyword: this.keyword,
          //    from: this.fromDate,
          //   to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.apology = response.data.data;
            this.apologyCount = response.data.totallength;
            // for (let i = 0; i < this.outpass.length; i++) {
            //   if (this.outpass[i].issueStatus == "Active") {
            //     this.outpass[i].issueStatus = "Issued";
            //   }
            // }
            this.Pagelength = Math.ceil(response.data.totallength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    //delete apology
    deleteCategory() {
      axios({
        url: "/incident/report/remove",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.delete = false;
          this.deletedialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            // this.$router.go(-1)
            this.snackbar = true;
            this.getApology();
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
            this.getApology();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
     formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
      formatDate2(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year
        //  +
        // " , " +
        // hours +
        // ":" +
        // minutes +
        // " " +
        // ampm;

      return strTime;
    },
     formatDate3(item) {
      var dt = new Date(item);
      // var day = dt.getDate();
      // var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        // day +
        // " " +
        // dt.slice(4, 7) +
        // " " +
        // year
        //  +
        // " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
  },
};
</script>
<style scoped>
.gradientCard {
  background: transparent
    linear-gradient(104deg, #434343 0%, #484848 52%, #2c2b2b 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 6px 6px 0px 0px;
  opacity: 1;
}
</style>